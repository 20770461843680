import { format } from "date-fns";

import { CountryType, IdType, RegionType } from "../types";

export const mapValues = (values: IdType[]) => values.map(value => value.id);

export const getCountryRegionIds = (regions: RegionType) => Object.keys(regions).map(key => parseInt(key));

export const getRegionIds = (regions: RegionType[], values: number[]) =>
  regions
    .filter(region => values.includes(region.id))
    .reduce<number[]>((accumulator, region) => {
      const children = regions.filter(r => r.parent_id === region.id);
      const childrenNew = children.filter(child => !accumulator.includes(child.id)).map(d => d.id);

      return [...accumulator, region.id, ...childrenNew];
    }, []);

export const filterCountriesByRegionIds = (countries: CountryType[], regions: RegionType[], regionValues: number[]) => {
  const regionIds = getRegionIds(regions, regionValues);

  return countries.filter(country =>
    country.regions ? getCountryRegionIds(country.regions).some(regionId => regionIds.includes(regionId)) : country,
  );
};

export const valuesToParam = (name: string, values?: number[]) => (values?.length ? `&${name}=${encodeURIComponent(values.join())}` : "");

export const dateToValue = (date: number) => new Date(date).toISOString().split("T")[0];

export const displayDate = (date: number | null) => (date ? format(new Date(date), "do MMM yyyy") : "--");
