import { initialValues } from "../store/slices/devices";
import { dateToValue, valuesToParam } from "./data";

export interface AllocationsValuesType {
  ituRegions: number[];
  regions: number[];
  countries: number[];
  frequencyBand: number;
  frequencies: number[][];
}

export const allocationsValuesToParams = (values: AllocationsValuesType) => {
  const [min, max] = values.frequencyBand === -1 ? [0, 100000] : values.frequencies[values.frequencyBand];
  const params: string[] = [];

  params.push(valuesToParam("showFuture", values.ituRegions));
  params.push(valuesToParam("itu_region_id", values.ituRegions));
  params.push(valuesToParam("region_id", values.regions));
  params.push(valuesToParam("country_id", values.countries));
  params.push(`&band_start=${min}&band_end=${max}`);

  return params.join("");
};

export interface NetworksValuesType {
  technologies: number[];
  features: number[];
  featuresControl: boolean;
  statuses: number[];
  ituRegions: number[];
  regions: number[];
  childRegions: number[];
  countries: number[];
  frequencyBands: number[];
  frequencyBandControl: boolean;
  ue: number[];
  downloadSpeed: number;
  downloadSpeedControl: string;
  publicStatus: number;
  startDate: number | null;
  endDate: number | null;
  spectrumBands: number[];
  satVendors: number[];
  satelitentn: number[];
  switchoffTechs: number | null;
  verticals: number[];
  subVerticals: number[];
  //ueSpeed: number;
  //ueSpeedControl: string;
}

export const networksValuesToParams = (values: NetworksValuesType) => {
  const params: string[] = [];

  params.push(valuesToParam("status_id", values.statuses));
  params.push(valuesToParam("itu_region_id", values.ituRegions));
  params.push(valuesToParam("region_id", values.regions));
  params.push(valuesToParam("country_id", values.countries));
  params.push(valuesToParam("feature_id", values.features));
  params.push(`&feature_control=${values.featuresControl ? "or" : "and"}`);
  if (values.publicStatus === 1) {
    params.push(valuesToParam("technology_id", values.technologies));
  } else if (values.publicStatus === 2) {
    params.push(valuesToParam("network_type_id", values.technologies));
  }
  params.push(valuesToParam("frequency_band_id", values.frequencyBands));
  //params.push(`&public_status_id=${values.publicStatus}`);

  params.push(`&frequency_band_control=${values.frequencyBandControl ? "or" : "and"}`);
  params.push(valuesToParam("ue_speed", values.ue));

  if (values.startDate && values.endDate) {
    params.push(`&announced_from=${values.startDate}&announced_to=${values.endDate}`);
  }

  if (values.downloadSpeedControl !== "all") {
    params.push(`&download_speed=${values.downloadSpeed}`);
    params.push(`&download_speed_control=${values.downloadSpeedControl}`);
  }
  if (values.spectrumBands.length) {
    params.push(valuesToParam("spectrum_band_id", values.spectrumBands));
  }
  if (values.verticals.length) {
    params.push(`&vertical_id=${values.verticals}`);
  }
  if (values.satVendors.length) {
    params.push(valuesToParam("satellite_vendor_id", values.satVendors));
  }
  if (values.satelitentn.length) {
    params.push(`&use_case_id=${values.satelitentn}`);
  }
  if (values.switchoffTechs) {
    params.push(`&sunset_technology_id=${values.switchoffTechs}`);
  }

  /*   console.log("values.switchoffTechs", values.switchoffTechs);
  if (values.switchoffTechs.length > 0) {
    params.push(valuesToParam("sunset_technology_id", values.switchoffTechs));
  } */

  /*
  if (values.ueSpeedControl !== "all") {
    params.push(`&ue_speed=${values.ueSpeed}`);
    params.push(`&ue_speed_control=${values.ueSpeedControl}`);
  }
  */

  return params.join("");
};

export interface ChipsetsValuesType {
  chipsetTypes: number[];
  chipsetVendors: number[];
  ue: number[];
  supports: number[];
  startDate: number | null;
  endDate: number | null;
  technologies: number[];
  commercial: number[];
}

export const chipsetsValuesToParams = (values: Partial<ChipsetsValuesType>) => {
  const chipsetVendors = valuesToParam("chipset_vendor_id", values.chipsetVendors);
  const chipsetTypes = valuesToParam("chipset_type_id", values.chipsetTypes);
  const supports = valuesToParam("support_id", values.supports);
  const technologies = valuesToParam("technology_id", values.technologies);
  const ue = valuesToParam("ue_cat", values.ue);
  const startDate = values.startDate ? `&date_start=${dateToValue(values.startDate)}` : "";
  const endDate = values.endDate ? `&date_end=${dateToValue(values.endDate)}` : "";
  const commercial = valuesToParam("commercial", values.commercial);

  return `${chipsetVendors}${chipsetTypes}${commercial}${technologies}${supports}${ue}${startDate}${endDate}`;
};

export interface DevicesValuesType {
  technologies: number[];
  formFactors: number[];
  fallbacks: number[];
  chipsets: number[];
  deviceVendors: number[];
  frequencyBands: number[];
  frequencyBandControl: boolean;
  commercial: number[];
  supports: number[];
  startDate: number | null;
  endDate: number | null;
}

export const devicesValuesToParams = (values: DevicesValuesType) => {
  const formFactors = valuesToParam("form_factor_id", values.formFactors);
  const fallbacks = valuesToParam("fallback_id", values.fallbacks);
  const deviceVendors = valuesToParam("device_vendor_id", values.deviceVendors);
  const frequencyBands = valuesToParam("frequency_band_id", values.frequencyBands);
  const technologies = values.technologies !== initialValues.technologies ? valuesToParam("technology_id", values.technologies) : "";
  const supports = valuesToParam("support_id", values.supports);
  const chipsets = valuesToParam("chipset_id", values.chipsets);
  const startDate = values.startDate ? `&date_start=${dateToValue(values.startDate)}` : "";
  const endDate = values.endDate ? `&date_end=${dateToValue(values.endDate)}` : "";
  const frequencyBandControl = `&frequency_band_control=${values.frequencyBandControl ? "or" : "and"}`;
  const commercial = valuesToParam("commercial", values.commercial);

  return `${technologies}${formFactors}${commercial}${fallbacks}${frequencyBands}${deviceVendors}${supports}${chipsets}${startDate}${endDate}${frequencyBandControl}`;
};

export interface OperatorsValuesType {
  search: string;
  countries: number[];
  regions: number[];
  childRegions: number[];
  //ituRegions: number[]; // TODO: copied from DevicesValuesType for consistency
  publicStatus: number;
  startDate: null;
  endDate: null;
  switchoffTechs: number[];
  upgradeTechs: number[];
}

export const operatorsValuesToParams = (values: OperatorsValuesType) => {
  const params: string[] = [];

  if (values.search) {
    params.push(`&fulltext=${values.search}`);
  }
  if (values.regions) {
    params.push(valuesToParam("region_id", values.regions));
  }
  // childRegions ar in fact regions for sake of API - above
  if (values.countries) {
    params.push(valuesToParam("country_id", values.countries));
  }
  if (values.publicStatus) {
    params.push(`&public_status_id=${values.publicStatus}`);
  }
  if (values.startDate && values.endDate) {
    params.push(`&sunset_from=${values.startDate}&sunset_to=${values.endDate}`);
  }
  if (values.switchoffTechs.length > 0) {
    params.push(valuesToParam("sunset_technology_id", values.switchoffTechs));
  }
  if (values.upgradeTechs.length > 0) {
    params.push(valuesToParam("upgrade_technology_id", values.upgradeTechs));
  }

  return params.join("");
};
