import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Breadcrumbs, Divider, Link, Typography } from "@mui/material";

import allRoutes from "../constants/routes";
import ContentWrapper from "../components/ContentWrapper";
import SaveList from "../components/SaveList";
import PageHeading from "../components/PageHeading";

const MySearches: React.FC = () => (
  <>
    <PageHeading title="My Searches">
      <Breadcrumbs aria-label="Breadcrumb">
        <Link href={allRoutes.dashboard.path}>Dashboard</Link>
        <Typography>My Searches</Typography>
      </Breadcrumbs>
    </PageHeading>
    <SaveList />
  </>
);

export default MySearches;
