import { Box } from "@mui/material";
import React from "react";
import ReactApexChart from 'react-apexcharts';

export interface TreeChartProps {
  title?: string;
  data: any[];
  cutoutPercentage?: number;
  rotation?: number;
  displayLegend?: boolean;
  loading?: boolean;
  valueSuffix?: string;
  colors: string[];
  setRef?: any; //(value: Doughnut) => void;
}

/* const series = [
  {
    data: [
      {
        x: 'New Delhi',
        y: 218,
        fillColor: '#ee238c'
      },
      {
        x: 'Kolkata',
        y: 149
      },
    ]
  }
] */

const options = {
   treemap: {
    dataLabels: {
      format: "truncate",
    },
  },
  legend: {
    show: false
  },
  chart: {
    height: 560,
  },
  title: {
    text: 'Verticals',
  },
  dataLabels: {
    distributed: true,
    style: {
      fontSize: '24px',
    },
    formatter: (input:string):string => input.length > 10 ? `${input.substring(0, 10)}...` : input
  },
  tooltip: {
    enabled: true,
    style: {
      fontSize: '14px',
    },
  }
}

const TreeChart: React.FC<TreeChartProps> = ({
  data = [],
}) => {
 
  return (
    <Box maxWidth={1280} width="100%">
      <Box height={[720, 720, 640, 560]} marginY={8} width="100%">
        <ReactApexChart type="treemap" options={options} color="#ee238c" series={data} height={560} /> 
      </Box>
    </Box>
  );
};

export default TreeChart;
