import { Box, Button, Divider } from "@mui/material";
import { Place, Public, SignalCellularAlt } from "@mui/icons-material";
import WavesIcon from "@mui/icons-material/Waves";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { useFormik } from "formik";
import { isEqual } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../constants/routes";
import { useSaveSearchMutation } from "../../store/apis/save";
import {
  useGetChildRegionsQuery,
  useGetCountriesQuery,
  useGetFrequencyBandsAllQuery,
  useGetItuRegionsQuery,
  useGetRegionsQuery,
  useGetSpectrumBandsQuery,
  useGetTechnologiesByNameQuery,
  useGetTechnologiesQuery,
} from "../../store/apis/search";
import { selectSaveToken } from "../../store/slices/auth";
import {
  initialValues,
  selectNetworksSlice,
  setAllSubRegions,
  setLastPage,
  setValues as setNetworksValues,
  setSavedValues,
  setSubRegions,
} from "../../store/slices/privateNetworks";
import { closeNetworksFilters, selectUiState, setFiltersOpen, setNetworksFilters } from "../../store/slices/ui";
import { IdType, ParentType } from "../../types";
import { mapValues, mapValuesToSaveData } from "../../utils";
import FilterList from "../FilterList";
import RadioFilter from "../RadioFilter";
import SearchFilter from "../SearchFilter";
import VerticalsFilter from "../VerticalsFilter";
import YearRangeFilter from "../YearRangeFilter";

const PrivateNetworksForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { networks: filters } = useSelector(selectUiState);
  const { values: valuesCurrent, lastSaved, lastPage, filteredSubRegions } = useSelector(selectNetworksSlice);
  const token = useSelector(selectSaveToken);

  console.log("filteredSubRegions", filteredSubRegions)

  const { values, setFieldValue, setValues, handleSubmit, handleReset } = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(setLastPage(pathname));
      const { path } = routes.privateNetworks;

      dispatch(setNetworksValues(values));
      dispatch(setFiltersOpen(false));

      if (pathname === path) {
        navigate(`${path}/search`);
      }
    },
  });

  const { data: ituRegionsOptions = [], isLoading: isItuRegionsLoading } = useGetItuRegionsQuery();
  const { data: spectrumBandsOptions = [], isLoading: isSpectrumBandsLoading } = useGetSpectrumBandsQuery();
  const { data: regionsOptions = [], isLoading: isRegionsLoading } = useGetRegionsQuery();
  const { data: childRegionOptions = [], isLoading: isChildRegionsLoading } = useGetChildRegionsQuery();
  const { data: countriesOptions = [], isLoading: isCountriesLoading } = useGetCountriesQuery({
    ituRegions: values.ituRegions,
    regions: values.regions,
  });
  //const { data: technologiesOptions = [], isLoading: isTechnologiesLoading } = useGetTechnologiesQuery();
  const { data: technologiesOptions = [], isLoading: isTechnologiesLoading } = useGetTechnologiesByNameQuery("private_network");
  const { data: frequencyBandsOptions = [[], [], []], isLoading: isFrequencyBandsLoading } = useGetFrequencyBandsAllQuery({
    technologies: values.technologies,
    sectionId: 3,
  });

  const [saveSearch] = useSaveSearchMutation();

  const [options1, options2, options3] = frequencyBandsOptions;

  useEffect(() => {
    const frequencyBandsFiltered = values.frequencyBands.filter(option => {
      return mapValues(options1).includes(option) || mapValues(options2).includes(option) || mapValues(options3).includes(option);
    });

    if (!isEqual(frequencyBandsFiltered, values.frequencyBands)) {
      setFieldValue("frequencyBands", frequencyBandsFiltered);
    }
  }, [options1, options2, options3, values.frequencyBands, setFieldValue]);

  useEffect(() => {
    console.log("set all subRegions", !isChildRegionsLoading,  setAllSubRegions.length);
    if (!isChildRegionsLoading) {
      dispatch(setAllSubRegions(childRegionOptions));
      dispatch(setSubRegions([]));
    }
  }, [childRegionOptions, dispatch, isChildRegionsLoading]);

  useEffect(() => {
    setValues(valuesCurrent);
  }, [setValues, valuesCurrent]);

  useEffect(() => {
    const countriesFiltered = values.countries.filter(option => mapValues(countriesOptions).includes(option));

    if (!isEqual(countriesFiltered, values.countries)) {
      setFieldValue("countries", countriesFiltered);
    }
  }, [countriesOptions, values.countries, setFieldValue]);

  const setFilterOpen = useCallback(
    (key: string, open: boolean) => {
      dispatch(setNetworksFilters({ ...filters, [key]: open }));
    },
    [dispatch, filters]
  );

  const handleSave = () => {
    dispatch(setSavedValues(valuesCurrent));
    saveSearch({
      sectionId: 3,
      data: mapValuesToSaveData(3, valuesCurrent),
    });
  };

  //Create a copy of the technologies options so we can change data
  let technologiesOptionsForUI = technologiesOptions.map(item => Object.assign({}, item));

  let radioFilterSelectedItem: number = -1;

  if (values.technologies.length > 0) {
    radioFilterSelectedItem = values.technologies[0];
  }

  // Taking a single parent type (technogoloy item) and creating a list of technology options revelant to the parent type
  function mapSelectionToTechnologiesList(value: ParentType): IdType[] {
    const singleOption = technologiesOptions.filter(option => option.id === value.id);
    return singleOption;
  }

  // this sets what radio option is selected
  let valueForRadioButton = technologiesOptions.find(item => item.id === radioFilterSelectedItem);

  function CanSearch() {
    if (!isEqual(lastPage, pathname)) {
      return true;
    } else {
      if (!isEqual(values, valuesCurrent)) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <FilterList
      disabled={isRegionsLoading || isChildRegionsLoading || isCountriesLoading || isTechnologiesLoading || isFrequencyBandsLoading}
      canSearch={CanSearch()}
      canSave={!isEqual(valuesCurrent, lastSaved)}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onSave={handleSave}
    >
      <Box paddingX={4} paddingY={2} textAlign="right" bgcolor="gunmetal.450">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            dispatch(closeNetworksFilters());
          }}
        >
          Close all
        </Button>
      </Box>
      <Divider />
      <RadioFilter
        title="Technologies"
        name="technologies-filter"
        tooltip="Filter by Technology Type"
        value={valueForRadioButton}
        icon={<SignalCellularAlt fontSize="small" />}
        options={technologiesOptionsForUI}
        loading={isTechnologiesLoading}
        open={filters.technologies}
        onChange={value =>
          value ? setFieldValue("technologies", mapValues(mapSelectionToTechnologiesList(value))) : setFieldValue("technologies", [])
        }
        setOpen={value => setFilterOpen("technologies", value)}
      />
      <SearchFilter
        title="ITU Regions"
        name="itu-regions-filter"
        tooltip="Filter the search by ITU Region"
        value={ituRegionsOptions.filter(option => values.ituRegions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={ituRegionsOptions}
        loading={isItuRegionsLoading}
        disabled={!ituRegionsOptions.length}
        open={filters.ituRegions}
        onChange={value => {
          setFieldValue("ituRegions", mapValues(value));
          setFieldValue("regions", []);

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("ituRegions", value)}
      />
      <SearchFilter
        title="Main Regions"
        name="regions-filter"
        tooltip="Filter the search by Region"
        value={regionsOptions.filter(option => values.regions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={regionsOptions}
        loading={isRegionsLoading}
        disabled={!regionsOptions.length}
        open={filters.regions}
        onChange={value => {
          //console.log("Set region");
          setFieldValue("ituRegions", []);
          setFieldValue("regions", mapValues(value));
          dispatch(setSubRegions(value.map(item => item.id)));

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("regions", value)}
      />
      <SearchFilter
        title="Sub Regions"
        name="child-regions-filter"
        tooltip="Filter the search by Sub Region"
        value={filteredSubRegions.filter(option => values.childRegions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={filteredSubRegions}
        loading={isChildRegionsLoading}
        disabled={!filteredSubRegions.length}
        open={filters.childRegions}
        onChange={value => {
          setFieldValue("ituRegions", []);
          setFieldValue("childRegions", mapValues(value));
          setFieldValue("regions", mapValues(value));

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("childRegions", value)}
      />
      <SearchFilter
        title="Country/Territories"
        name="countries-filter"
        tooltip="Filter by specific country"
        value={countriesOptions.filter(option => values.countries.includes(option.id))}
        icon={<Place fontSize="small" />}
        options={countriesOptions}
        loading={isCountriesLoading}
        disabled={!countriesOptions.length}
        open={filters.countries}
        onChange={value => setFieldValue("countries", mapValues(value))}
        setOpen={value => setFilterOpen("countries", value)}
      />

      <VerticalsFilter
        title="Verticals"
        name="verticals-filter"
        tooltip="Filter the search by Vertical"
        value={[]}
        icon={<BubbleChartIcon fontSize="small" />}
        open={filters.verticals}
        onChange={value => setFieldValue("verticals", value)}
        setOpen={value => setFilterOpen("verticals", value)}
      />

      <YearRangeFilter
        title="Year"
        name="year-range-filter"
        tooltip="Search by year range."
        value={[values.startDate, values.endDate]}
        open={filters.dates}
        onChange={(value: (number | null)[]) => {
          const [startDate, endDate] = value;

          setFieldValue("startDate", startDate);
          setFieldValue("endDate", endDate);
        }}
        setOpen={(open: boolean) => setFilterOpen("dates", open)}
      />
      <SearchFilter
        title="Spectrum Bands"
        name="spectrum-bands-filter"
        tooltip="Filter the search by Spectrum Bands"
        value={spectrumBandsOptions.filter(option => values.spectrumBands.includes(option.id))}
        icon={<WavesIcon fontSize="small" />}
        options={spectrumBandsOptions}
        loading={isSpectrumBandsLoading}
        disabled={!spectrumBandsOptions.length}
        open={filters.spectrumBands}
        onChange={value => {
          setFieldValue("spectrumBands", mapValues(value));
          setFieldValue("regions", []);

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("spectrumBands", value)}
      />
    </FilterList>
  );
};

export default PrivateNetworksForm;
