import React from "react";
import { Button, Grid, IconButton, Typography, Chip, CircularProgress } from "@mui/material";
import { Close } from "@mui/icons-material";

import { sectionColors } from "../../constants/routes";

export interface SaveItemProps {
  title: string;
  category?: string;
  parameters?: any;
  date: string;
  sectionId?: number;
  loading?: boolean;
  onDelete?: () => void;
  onRun?: () => void;
}

const createTitleFromParameters = (parameters: any) => {
  // console.log("parameters", parameters);

  //@ts-ignore
  function removePropFromObject(obj, prop) {
    const { [prop]: _, ...rest } = obj;
    return { ...rest };
  }

  //@ts-ignore
  const allParamsObject = removePropFromObject(parameters, "section_id") as any as parameters;

  //@ts-ignore
  const allParams = [];
  let index = 0;

  for (const [key, value] of Object.entries(parameters)) {
    //    allParams.push(`${key}: ${value}`);
    let thisKey = `${key}`;
    if (thisKey !== "section_id") {
      allParams.push({ name: thisKey, searValue: value });
      /* index++;
    if (index < Object.entries(parameters).length - 1) {
      allParams.push(", ");
    } */
    }
  }

  /*   if (allParams.length > 1) {
    allParams.splice(allParams.length - 1, 0, " and ");
  } */
  // remove section_id and ","
  /* allParams.shift();
  allParams.shift(); */

  /*  let combinedString = allParams.join("");
  if (combinedString.length > 80) {
    combinedString = combinedString.substring(0, 80) + ", ...";
  } */

  return (
    <>
      {allParams.map((item, index) => {
        return (
          <SearchParamItem
            key={index}
            name={item.name}
            searchValue={item.searValue}
            index={index}
            lastItem={index === allParams.length - 1}
          />
        );
      })}
    </>
  );
};

const cleanUpParameter = (thisKey: any) => {
  if (thisKey.indexOf("_id") > -1) {
    thisKey = thisKey.substring(0, thisKey.length - 3);
  }
  return thisKey.replaceAll("_", " ");
};
const SearchParamItem: React.FC<any> = ({ name, searchValue, index, lastItem }) => {
  return (
    <>
      {index > 0 && <>{lastItem ? " and " : ", "}</>}
      <strong title={JSON.stringify(searchValue)}>{cleanUpParameter(name)}</strong>
    </>
  );
};

const formatDate = (myDate: any) => {
  const dateObject = new Date(myDate);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const mins = dateObject.getUTCMinutes();
  const time = hours + ":" + (mins > 9 ? mins : "0" + mins);
  return day + "/" + month + "/" + year + " " + time;
};

const SaveItem: React.FC<SaveItemProps> = ({ title, category, parameters, date, sectionId = 1, loading, onDelete, onRun }) => (
  <Grid container alignItems="center" spacing={4} wrap="nowrap">
    {/* <Grid item zeroMinWidth>
      <Chip label={`${category} Search`} color="primary" style={{ backgroundColor: sectionColors[sectionId - 1] }} />
    </Grid> */}
    <Grid item xs zeroMinWidth>
      <Typography noWrap>
        {/* <strong>{title}</strong> - {`${new Date(date).toUTCString()}`} */}
        <span>
          By <>{createTitleFromParameters(parameters)}</>
        </span>{" "}
        &nbsp; {`${formatDate(date)}`}
      </Typography>
    </Grid>
    <Grid item zeroMinWidth>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item zeroMinWidth>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onRun && onRun()}
            style={{ backgroundColor: sectionColors[sectionId - 1] }}
          >
            Re-Run
          </Button>
        </Grid>
        <Grid item zeroMinWidth>
          <IconButton onClick={() => onDelete && onDelete()} size="large">
            {loading ? <CircularProgress size={20} thickness={6} /> : <Close />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default SaveItem;
