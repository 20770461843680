import React, { useEffect, useState } from "react";
import { Box, RadioGroup, FormControlLabel, Radio, Typography, Grid, Button } from "@mui/material";

import PieChart from "../PieChart";
import { exportImage } from "../../utils";
import { colors } from "../../constants";
import { Image } from "@mui/icons-material";

interface DevicesPieChartProps {
  data: any;
  type: string;
  setType: (numeric: string) => void;
  loading?: boolean;
}

const DevicesPieChart: React.FC<DevicesPieChartProps> = ({ data = [], type, setType, loading }) => {
  const [numeric, setNumeric] = useState("total");
  const [total, setTotal] = useState(0);
  const [canvas, setCanvas] = useState<any>(null);

  useEffect(() => {
    if (data.length !== total) {
      setTotal(data.length);
    }
  }, [data, total]);

  const mappedData = data.map((item: any) => ({
    label: type === "chart_region" ? item.region_title : item.status_title,
    value: numeric === "total" ? item.numeric : item.percentage,
  }));

  return (
    <Box paddingY={4}>
      <Box marginY={16}>
        <RadioGroup
          name="type"
          value={type}
          row
          onChange={(_, value) => {
            setType(value);
          }}
        >
          <FormControlLabel value="chart_region" control={<Radio color="primary" />} label="Chart by region" />
          <FormControlLabel value="chart_status" control={<Radio color="primary" />} label="Chart by status" />
        </RadioGroup>
      </Box>
      <Box marginY={16}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">{type === "chart_region" ? "Chart Grouped by Region" : "Chart Grouped by Status"}</Typography>
          </Grid>
          <Grid item>
            <Button
              startIcon={<Image />}
              variant="outlined"
              onClick={() => {
                if (canvas) {
                  exportImage(canvas.canvas, `nts-pie-chart-${new Date().getTime()}`);
                }
              }}
            >
              Download PNG
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginY={16}>
        <RadioGroup
          name="numeric"
          value={numeric}
          row
          onChange={event => {
            setNumeric(event.target.value);
          }}
        >
          <FormControlLabel disabled={loading} value="total" control={<Radio color="primary" />} label="Show as total" />
          <FormControlLabel disabled={loading} value="percent" control={<Radio color="primary" />} label="Show as percent" />
        </RadioGroup>
      </Box>
      {mappedData.length > 0 && (
        <PieChart loading={loading} data={mappedData} valueSuffix={numeric === "percent" ? "%" : ""} colors={colors} setRef={setCanvas} />
      )}
    </Box>
  );
};

export default DevicesPieChart;
