import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../constants";
import { useGetVerticalsAsTreedataQuery } from "../../store/apis/search";
import TreeChart from "../TreeChart";
import {NetworksValuesType} from "../../utils";
import { VerticalTreeType } from "../../types";

interface NetworkTreeChartProps {
  filters: NetworksValuesType;
  loading?: boolean;
}

interface TreeChartType {
  x: string;
  y: number;
}

const NetworkTreeChart: React.FC<NetworkTreeChartProps> = ({ filters, loading }) => {
  const [canvas, setCanvas] = useState<any>(null);
  const [mappedData, setmappedData] = useState<TreeChartType[] | null>(null);
  const { data } = useGetVerticalsAsTreedataQuery({ values: filters });
  
  useEffect(() => {
     // console.log("data", data)
    if (data) {
       const newData = data.map((item: VerticalTreeType) => ({
       x: item.title,
       y: item.count,
     }));
     setmappedData(newData)
    } 
  }, [data]);

  return (
    <Box paddingY={4}>
      {mappedData ? (
        <TreeChart loading={loading} data={[{data: mappedData}]} colors={colors} setRef={setCanvas} />
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default NetworkTreeChart;
