import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, /*Link, CircularProgress,*/ Divider, Link } from "@mui/material";

import allRoutes from "../../constants/routes";
import { setValues as setDevicesValues } from "../../store/slices/devices";
import { setValues as setNetworksValues } from "../../store/slices/networks";
import { setValues as setOperatorsValues } from "../../store/slices/operators";
import { setValues as setChipsetsValues } from "../../store/slices/chipsets";
import { AllocationsValuesType, setValues as setAllocationsValues } from "../../store/slices/allocations";
import { useDeleteSearchMutation, useGetSearchesQuery } from "../../store/apis/save";
import { getApiToken, selectSaveToken, selectUsername } from "../../store/slices/auth";
import { ChipsetsValuesType, DevicesValuesType, NetworksValuesType, OperatorsValuesType, mapSaveDataToValues } from "../../utils";
import ContentWrapper from "../ContentWrapper";
import SaveItem from "../SaveItem";

export interface SaveListProps {
  sectionId?: number;
}

const SaveList: React.FC<SaveListProps> = ({ sectionId }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const token = useSelector(selectSaveToken);
  const username = useSelector(selectUsername);
  const { data = [], error, isUninitialized, isLoading } = useGetSearchesQuery({ token, sectionId }, { skip: !token });
  const [deleteSearch] = useDeleteSearchMutation();

  useEffect(() => {
    if (error && username) {
      dispatch(getApiToken(username));
    }
  }, [dispatch, error, username]);

  const onRun = (id: number) => {
    const item = data.find(item => item.id === id);

    if (item) {
      switch (item.section_id) {
        case 1:
          dispatch(setDevicesValues(mapSaveDataToValues(item.section_id, item.parameters) as DevicesValuesType));
          navigate(`${allRoutes.devices.path}/search`);
          break;

        case 2:
          dispatch(setNetworksValues(mapSaveDataToValues(item.section_id, item.parameters) as NetworksValuesType));
          navigate(`${allRoutes.publicNetworks.path}/search`);
          break;

        case 3:
          dispatch(setNetworksValues(mapSaveDataToValues(item.section_id, item.parameters) as NetworksValuesType));
          navigate(`${allRoutes.privateNetworks.path}/search`);
          break;

        case 4:
          dispatch(setOperatorsValues(mapSaveDataToValues(item.section_id, item.parameters) as OperatorsValuesType));
          navigate(`${allRoutes.operators.path}/search`);
          break;

        case 5:
          dispatch(setChipsetsValues(mapSaveDataToValues(item.section_id, item.parameters) as ChipsetsValuesType));
          navigate(`${allRoutes.chipsets.path}/search`);
          break;

        case 6:
          // @ts-ignore
          dispatch(setAllocationsValues(mapSaveDataToValues(item.section_id, item.parameters) as AllocationsValuesType));
          navigate(`${allRoutes.spectrum_assignment.path}/search`);
          break;
      }
    }
  };

  const onlyOneSection = () => {
    return (
      <>
        <Divider />
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <SaveItem
              title={item.title}
              parameters={item.parameters}
              sectionId={item.section_id}
              category={item.section_title}
              date={item.created_at}
              onDelete={() => deleteSearch({ id: item.id, token })}
              onRun={() => onRun(item.id)}
            />
            <Divider />
          </React.Fragment>
        ))}
      </>
    );
  };
  const showSection = (section_id: number) => {
    const filtered = data.filter(a => a.section_id === section_id);
    if (filtered.length > 0)
      return (
        <>
          <Typography variant="h4" style={{ marginBottom: "15px" }}>
            {section_id === 1 && "Devices"}
            {section_id === 2 && "Public Networks"}
            {section_id === 3 && "Private Networks"}
            {section_id === 4 && "Operators"}
            {section_id === 5 && "Chipsets"}
            {section_id === 6 && "Spectrum Assignments"}
          </Typography>
          <Divider />
          {filtered.map((item, index) => (
            <React.Fragment key={index}>
              <SaveItem
                title={item.title}
                parameters={item.parameters}
                sectionId={item.section_id}
                category={item.section_title}
                date={item.created_at}
                onDelete={() => deleteSearch({ id: item.id, token })}
                onRun={() => onRun(item.id)}
              />
              <Divider />
            </React.Fragment>
          ))}
          <br />
          <br />
        </>
      );
  };

  return (
    <ContentWrapper body inset>
      {sectionId && (
        <Box marginY={16} width="80%">
          <Typography variant="h3" gutterBottom>
            Saved Searches
          </Typography>
          <Typography>
            Searches can be saved and will show on the list below, so you can re-run searches later without having to re-select filters each
            time.
          </Typography>
        </Box>
      )}
      <Box marginY={16} width="80%">
        {isUninitialized || isLoading || error ? (
          <>{/* <CircularProgress /> */}</>
        ) : data.length > 0 ? (
          <>{sectionId ? onlyOneSection() : [1, 2, 3, 4, 5, 6].map(i => showSection(i))}</>
        ) : (
          <Typography>No saved searches.</Typography>
        )}
      </Box>
      {sectionId && (
        <Box marginY={16} width="100%" maxWidth={800}>
          <Typography>
            <Link href={allRoutes.my_searches.path}>See all saved Searches</Link>
          </Typography>
        </Box>
      )}
    </ContentWrapper>
  );
};

export default SaveList;
